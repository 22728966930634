import React from 'react';
import './PlainLanding.css';

const PlainLanding = () => {
  return (
    <div className="PlainLanding-container">
      <div className="PlainLanding-text-col">
        <h1 className="PlainLanding-text">Legal Information and Resources</h1>
      </div>


    </div>
  );
};

export default PlainLanding;
