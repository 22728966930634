import React from "react";
import Forgot from "../../../components/Authencation/Forgot/Forgot";

function ForgotPage() {
  return (
    <div>
      <Forgot />
    </div>
  );
}

export default ForgotPage;
