import React from "react";
import HomeLandingData from "../../../../../data/homeData/homeLandingData";
import "./HomeLanding.css";
import { connect } from 'react-redux';
import {updateData} from '../../../../../redux/action/dataAction'



const HomeLanding = ({ data, updateData })  => {
  console.log(data)
  const {
    title,
    spanText,
    paragraph,
    buttonText,
    imageUrl,
    imageAlt,
    demoLink,
  } = HomeLandingData;

  return (
    <div className="HomeLanding-main">
      <div className="HomeLanding-col">
        <div className="HomeLanding-image-container">
          <img src={imageUrl} alt={imageAlt} />
        </div>
      </div>
      <div className="HomeLanding-col">
        <div className="HomeLanding-text-container ">
          <h1>{title}</h1> <span>{spanText}</span> <br />
          <br />
          <p className="HomeLanding-praragraph">{paragraph}</p>
          <br />
          <div className="HomeLanding-button-container">
            <a className="HomeLanding-button" href={demoLink}>
              {buttonText}
            </a>
            <br />
            <br />
          </div>
          <div className="HomeLanding-card-container">
            {HomeLandingData.technologies.map((tech, index) => (
              <div key={index} className="HomeLanding-card">
                <h2>{tech.techTitle}</h2>
                <p>{tech.techDescription}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};




const mapStateToProps = (state) => ({
  data: state.data,
});

const mapDispatchToProps = {
  updateData,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLanding);
