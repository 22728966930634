

const ProfilePageData = {
    LandingContent: {
      title: "Profile ",
      paragraph: "At Imaggar Technologies, we are dedicated to transforming the digital landscape through cutting-edge AI solutions. Our commitment is to streamline operations, captivate audiences with immersive technologies, and embrace the latest innovations for unparalleled efficiency and productivity.",
      imageAlt: "Imaggar Technologies Office",
      
    },
    
    
  };
  
  export default ProfilePageData;
  