const userProfileData = {
  userData: {
    userName: "Mohamed Thowfick M",
    email: "user@example.com",
    dob: "1990-01-01",
    phoneNumber: "+1234567890",
    doorNumber: "22",
    street: "KR Road",
    district: "TNJ",
    state: "Tamil Nadu",
    country: "India",
    pincode: "613001",
    // ... other user data ...
  },
  educationalDetails: [
    {
      institution: "University A",
      qualification: "UG",
      courseName: "Computer Science",
      yearOfStudy: "Final Year",
      passedOutYear: "2020",
      country: "Country A",
      state: "State A",
      district: "District A",
    },
    // ... other educational details
  ],

  workExperienceDetails: [
    {
      companyName: "Company X",
      role: "Software Developer",
      yearOfExperience: "3",
      workModel: "Onsite",
      currentlyWorking: false,
    },
    // ... other work experience details
  ],

  socialMediaDetails: [
    {
      platform: "Linkedin",
      link: "https://www.linkedin.com/in/thowfickofficial/",
    },
    // ... other social media details
  ],
};

export default userProfileData;
