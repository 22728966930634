// DesktopRoutes.js


import React from "react";
import {
  Route,
  Routes as RouterRoutes,
  Navigate,
  useParams,
} from "react-router-dom";
import Home from "./Pages/HomePage";
import Header from "./components/General/Header";
import Footer from "./components/General/Footer";
import SubscribePopup from "./components/General/SubscribePopup";
// import Careers from "./Pages/CarrersPage";
import ContactPage from "./Pages/ContactPage";
import AboutUs from "./Pages/AboutusPage";
import JobOpening from "./Pages/JobOpeningPage";
import SubTopicPage from "./Pages/subTopicPage";
import JobDescription from "./Pages/jobDescriptionPage";
// import PreliminaryRound from "./Pages/preliminaryRound";
import Legal from "./Pages/AdditionPages/legalPage";
import Login from './Pages/AuthPage/LoginPage'
import routeData from "../../data/routeData";
import RegisterPage from "./Pages/AuthPage/RegisterPage";
import ForgotPage from "./Pages/AuthPage/ForgotPage";
import Intership from "./Pages/InternshipPage";
// import ChatBot from "./components/ChatBot/ChatBot";
// import ProductPage from "./Pages/ProductsPages";
import ServicePage from "./Pages/ServicePage";
import GetstartedPage from "./Pages/GetstartedPage";
import ProfilePage from "./Pages/ProfilePage";

const DesktopRoutes = () => {
  return (
    <RouterRoutes>
      <Route
        exact
        path="/"
        element={
          <>
            {/* <ChatBot /> */}
            <Header />
            <Home />
            <SubscribePopup />
            <Footer />
          </>
        }
      />
      {/* <Route
        path="/careers"
        element={
          <>
            <Header />
            <Careers />
            <Footer />
          </>
        }
      /> */}
      <Route
        path="/careers/job-openings"
        element={
          <>
            <Header />
            <JobOpening />
            <Footer />
          </>
        }
      />
      <Route
        path="/careers/internship"
        element={
          <>
            <Header />
            <Intership />
            <Footer />
          </>
        }
      />
      <Route
        path="/login"
        element={
          <>
            <Header />
            <Login />
            <Footer />
          </>
        }
      />
      <Route
        path="/register"
        element={
          <>
            <Header />
            <RegisterPage />
            <Footer />
          </>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <>
            <Header />
            <ForgotPage />
            <Footer />
          </>
        }
      />
      <Route
        path="/about"
        element={
          <>
            <Header />
            <AboutUs />
            <Footer />
          </>
        }
      />
      <Route
        path="/contact"
        element={
          <>
            <Header />
            <ContactPage />
            <Footer />
          </>
        }
      />
      {/* <Route
        path="/products"
        element={
          <>
            <Header />
            <ProductPage />
            <Footer />
          </>
        }
      /> */}
      <Route
        path="/services"
        element={
          <>
            <Header />
            <ServicePage />
            <Footer />
          </>
        }
      />
      <Route
        path="/get-started"
        element={
          <>
            <Header />
            <GetstartedPage />
            <Footer />
          </>
        }
      />

      <Route
        path="/careers/job-openings/jobdescription/:routeID"
        element={<JobDescriptionRoute />}
      />
      <Route
        path="/profile"
        element={
          <>
            <Header />
            <ProfilePage />
            <Footer />
          </>
        }
      />
     

      <Route
        path="/legal"
        element={
          <>
            <Header />
            <Legal />
            <Footer />
          </>
        }
      />

      {routeData.map(({ url, Data }) => (
        <Route
          key={url}
          path={url}
          element={
            <>
              <Header />
              <SubTopicPage Data={Data} />
              <Footer />
            </>
          }
        />
      ))}

      <Route path="*" element={<Navigate to="/" />} />
    </RouterRoutes>
  );
};

const JobDescriptionRoute = () => {
  const { routeID } = useParams();

  return (
    <>
      <Header />
      <JobDescription routeID={routeID} />
      <Footer />
    </>
  );
};
export default DesktopRoutes;
